<template>
  <div>数据中心组件
  </div>
</template>

<script>
export default {

}
</script>

<style lang='less' scoped>
</style>
