<template>
  <div class="top">
    <div class="Login-body">
      <!--头部区域-->
      <header>
        <img class="logo-img" src="https://yzwb-snz.oss-cn-hangzhou.aliyuncs.com/mobile-admin/logo2.4afa1218.png" alt="少年志">
        <h4>少年志APP资讯审核管理后台</h4>
      </header>
      <!--登录框-->
      <van-form @submit="onSubmit" :model="form" class="Login-form">
        <van-field ref='usernameInput' v-model="username" name="用户名" label="用户名" placeholder="用户名" :rules="[{ required: true, message: '请填写用户名' }]" />
        <van-field ref='passwordInput' v-model="password" type="password" name="密码" label="密码" placeholder="密码" :rules="[{ required: true, message: '请填写密码' }]" />
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">登录</van-button>
        </div>
      </van-form>
      <div class="Login-copyright">
        Copyright©江苏新华云教育科技有限公司
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import Cookies from 'js-cookie'

import {
  login,
  userInfo
} from '@/api/index.js'
export default {
  name: 'Login',
  data() {
    return {
      socialLogining: true,
      error: false,
      errorMsg: '',
      tabName: 'username',
      saveLogin: true,
      loading: false,
      // 用户名
      username: '',
      // 密码
      password: '',
      sending: false,
      showGif: false,
      sended: false,
      count: 60,
      countButton: '60 s',
      maxLength: 6,
      errorCode: '',
      form: {
        username: '',
        password: '',
        error_message: ''
      }
    }
  },
  mounted() {
    // 首次加载页面时，从 localStorage 读取账号和密码并填充输入框
    this.username = localStorage.getItem('username')
    this.password = localStorage.getItem('password')
    if (this.username && this.password) {
      this.$nextTick(() => {
        this.$refs.usernameInput.value = this.username
        this.$refs.passwordInput.value = this.password
      })
    }
  },
  methods: {
    onSubmit(values) {
      login({
        username: this.username,
        password: this.password,
        saveLogin: this.saveLogin
      }).then((res) => {
        if (res.success === true) {
          this.setStore('accessToken', res.result)
          // 获取用户信息
          userInfo().then((res) => {
            if (res.success === true) {
              this.setStore('saveLogin', this.saveLogin)
              if (this.saveLogin) {
                // 保存7天
                Cookies.set('userInfo', JSON.stringify(res.result), {
                  expires: 7
                })
              } else {
                Cookies.set('userInfo', JSON.stringify(res.result))
              }
              this.setStore('userInfo', res.result)
              // 姓名
              this.setStore('name', res.result.name)
              // 头像地址
              this.setStore('setAvatarPath', res.result.avatar)
              // 权限名称
              this.setStore('role', res.result.roles[0].name)
              // 记住用户名
              this.setStore('username', this.username)
              // 记住密码
              this.setStore('password', this.password)
              // 加载菜单
              this.$router.push({
                name: 'home'
              })
            } else {
              this.loading = false
            }
          })
        } else {
          this.loading = false
          // 登陆失败
          localStorage.removeItem('accessToken')
          this.error_message = res.message
          Dialog.alert({
            message: this.error_message
          }).then(() => {
            // on close
          })
        }
      })
    }
  }
}
</script>

<style lang='less'>
.top {
  display: flex;
  height: 600px;
  justify-content: center;
  /*flex-direction: column;*/
  align-items: center;
}
.Login-body {
  display: flex;
  width: 500px;
  height: 520px;
  margin: 2px;
  text-align: center;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  /*align-items: flex-start;*/
}
.logo-img {
  width: 200px;
  height: 100px;
  margin-top: 40%;
}
.Login-form {
  margin-top: 15%;
  height: 50%;
}
.Login-copyright {
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  color: #949494;
  line-height: 40px;
  margin-top: 10%;
}
.ivu-message {
  display: none;
}
</style>
