<template>
  <div class="container">
    <Card style="height: 100%;width: 100%;">
      <h2 style="margin-bottom: 10px;">精彩呈现霸屏位配置</h2>
      <div class="line"></div>
      <Row class="card-wrapper">
        <Form ref="configForm" :model="configForm" :label-width="100" style="width: 100%">
          <Form-item label="前置内容数：" prop="preNum" :rules="rules.preNum" class="wrapper-title1">
            <Input type="number" v-model="configForm.preNum" autocomplete="off" />
          </Form-item>
          <vuedraggable v-model="configForm.bapingCfgList">
            <div class="top-box" v-for="(item, i) in configForm.bapingCfgList" :key="item.id">
              <FormItem :prop="`bapingCfgList[${i}].targetType`" :rules="rules.targetType" class="type-selection">
                <Select v-model="item.targetType" draggable="false">
                  <Option :value="1" label="资讯"></Option>
                  <Option :value="2" label="活动"></Option>
                  <Option :value="3" label="赛事"></Option>
                  <Option :value="4" label="课程"></Option>
                  <Option :value="6" label="专题"></Option>
                  <Option :value="7" label="赛事专栏"></Option>
                  <Option :value="8" label="活动专栏"></Option>
                  <Option :value="9" label="专题专栏"></Option>
                  <Option :value="10" label="资讯专栏"></Option>
                  <Option :value="11" label="直播页面"></Option>
                  <Option :value="12" label="答题页面"></Option>
                  <Option :value="13" label="课程页面"></Option>
                </Select>
              </FormItem>
              <div v-if="item.targetType <=6" class="flex-start">
                <FormItem :prop="`bapingCfgList[${i}].targetId`" :rules="rules.targetId" :label-width="10">
                  <Select v-model="item.targetId" placeholder="请选择关联卡片内容" label-in-value filterable remote :remote-method="(query) => { handleSearch(query, item) }" :loading="item.loading" @on-change="handleChange(...arguments, item)" @on-open-change="handleSelect(item)" style="width:200px">
                    <Option v-for="content in item.contentOptions" :key="content.id" :value="content.id" :label="content.title">
                    </Option>
                  </Select>
                  <!-- <Icon type="ios-close" @click="handleDeleteContent(i)" class="del-item" /> -->
                  <Button type="error" @click="handleDeleteContent(i)" style="margin-left: 10px;">删除</Button>
                </FormItem>
              </div>
              <div v-if="item.targetType >=7">
                <Form-item label="上传背景图：" :label-width="120" :prop="`bapingCfgList[${i}].targetTitle`" :rules="rules.imgUrl">
                  <upload-pic-input readonly v-model="item.targetTitle" ref="upload" style="display: inline-flex"></upload-pic-input>
                  <Button type="error" @click="handleDeleteContent(i)" style="margin-left: 10px;">删除</Button>
                </Form-item>
              </div>
            </div>
          </vuedraggable>
          <Form-item class="wrapper-title">
            <Button type="primary" class="button-item" @click="handleSubmit">保存</Button>
            <Button type="primary" class="button-item" style="margin-left: 20px;" @click="handleCreatContent">+关联内容</Button>
          </Form-item>
        </Form>
      </Row>
    </Card>
  </div>
</template>

<script>
import vuedraggable from 'vuedraggable'
import uploadPicInput from '@/components/upload-pic-input-level'
import { getDominateScreenCfgInfo, editDominateScreenCfgInfo, getTopicList } from '@/api/index.js'
import { searchContent } from '@/api/specialSubjectManage'
export default {
  components: {
    uploadPicInput,
    vuedraggable
  },
  data() {
    const validatePreNum = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入前置内容数'))
      } else {
        callback()
      }
    }
    return {
      configForm: {
        preNum: null,
        bapingCfgList: []
      },
      uploadFileUrl: '/admin/fm/upload/file',
      rules: {
        preNum: [
          { validator: validatePreNum }
        ],
        imgUrl: [
          { required: true, message: '请上传卡片背景图' }
        ],
        targetType: [
          { required: true, message: '请选择卡片类型' }
        ],
        targetId: [
          { required: true, message: '请选择关联卡片内容' }
        ]
      }
    }
  },
  created() {
    this.onGetDominateScreenCfgInfo()
  },
  methods: {
    // 获取霸屏配置信息
    onGetDominateScreenCfgInfo() {
      getDominateScreenCfgInfo().then(res => {
        console.log('res', res)
        this.configForm.preNum = res.result.preNum
        this.configForm.bapingCfgList = []
        const bapingCfgList = res.result.bapingCfgList
        console.log(bapingCfgList)
        bapingCfgList.forEach((item, index) => {
          const bapingCfg = {
            targetType: item.targetType,
            targetId: item.targetId,
            sort: item.sort,
            id: item.id,
            isInit: true,
            targetTitle: item.targetTitle,
            contentOptions: [{
              id: item.targetId,
              title: item.targetTitle
            }]
          }
          console.log(bapingCfg)
          this.configForm.bapingCfgList.push(bapingCfg)
        })
        console.log('configForm', this.configForm)
      })
    },
    // 新增关联内容
    handleCreatContent() {
      const item = {
        targetType: 1,
        targetId: null,
        targetTitle: null,
        contentOptions: []
      }
      this.configForm.bapingCfgList.push(item)
      this.$nextTick(() => {
        this.getContentOptions(1, this.configForm.bapingCfgList[this.configForm.bapingCfgList.length - 1])
      })
    },
    handleSearch(query, item) {
      console.log('handleSearch')
      // 初始化,专题,不执行查询
      if (item.isInit || item.targetType === 6) {
        return
      }
      item.loading = true
      searchContent({
        type: item.targetType,
        content: query
      }).then(res => {
        item.loading = false
        if (res.result && res.result.length > 0) {
          item.contentOptions = []
          item.contentOptions = res.result
          item.isInit = false
          this.$forceUpdate()
        }
      })
    },
    handleSelect(item) {
      if (item.isInit) {
        this.getContentOptions(item.targetType, item, true)
      }
    },
    handleChange(args, item) {
      console.log('args', args)
      if (args) {
        item.targetId = args.value
        item.targetTitle = args.label
      }
    },
    // 资讯等关键字搜索
    getContentOptions(targetType, item, flag) {
      item.contentOptions = []
      item.loading = true
      if (!flag) {
        item.targetId = ''
      }
      this.$forceUpdate()
      if (targetType === 6) {
        getTopicList({
        }).then(res => {
          item.contentOptions = res
          item.loading = false
          item.isInit = false
          this.$forceUpdate()
        })
      } else if (targetType > 6) {
        item.targetId = ''
        item.targetTitle = ''
      } else {
        searchContent({
          type: targetType,
          content: ''
        }).then(res => {
          item.contentOptions = res.result.splice(0, 100)
          item.loading = false
          item.isInit = false
          this.$forceUpdate()
        })
      }
    },
    handleDeleteContent(index) {
      this.configForm.bapingCfgList.splice(index, 1)
    },
    // 提交配置
    handleSubmit() {
      console.log(this.configForm)
      this.$refs.configForm.validate(valid => {
        if (valid) {
          this.configForm.bapingCfgList.forEach((element, index) => {
            element.sort = index + 1
            if (element.targetType > 6) {
              element.targetId = -1
            }
          })
          const reqData = JSON.parse(JSON.stringify(this.configForm))
          console.log(reqData)
          editDominateScreenCfgInfo(reqData).then(res => {
            console.log(res)
            this.$Message.success('霸屏位配置成功')
            this.onGetDominateScreenCfgInfo()
          }).catch(() => {
            this.$Message.warning('霸屏位配置失败')
          })
        }
      })
    },
    // 取消配置
    handleClose() {
      console.log(this.$store.state.app)
      this.$store.commit('removeTag', this.$store.state.app.currentPageName)
      this.$store.commit('closePage', this.$store.state.app.currentPageName)
      var currentView = this.$store.state.app.currentPageName
      var latestView = this.$store.state.app.pageOpenedList.slice(-1)[0]
      if (latestView) {
        this.$router.push(latestView)
      } else {
        if (currentView.name === '首页') {
          this.$router.replace({ path: '/redirect' + currentView.fullPath })
        } else {
          this.$router.push('/')
        }
      }
    }

  }
}
</script>
<style lang="less" scoped>
/deep/ .ivu-form-item-content {
  margin-left: 0px !important;
}
/deep/ .ivu-card-bordered {
  border: 0;
}
/deep/ .ivu-select-item {
  white-space: normal;
}
/deep/ .ivu-select-dropdown {
  max-height: 500px;
  overflow: visible;
}
.top-box {
  width: 80%;
  background: #ffffff;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  border: 1px solid #eaf0f6;
  //margin-bottom: 16px;
  margin-left: 10px;
  margin-bottom: 10px;
}
.type-selection {
  width: 200px;
  padding-top: 10px;
  padding-left: 10px;
  margin-bottom: 10px;

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.flex-start {
  display: flex;
  padding-left: 10px;
  justify-content: flex-start;
}
.wrapper-title1 {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
}
.wrapper-title {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.del-item {
  font-size: 20px;
  cursor: pointer;
  line-height: 32px;
}

.container {
  display: flex;
  .card-wrapper {
    padding-top: 0px;

    h3 {
      margin-bottom: 20px;
    }
  }
}
.button-item {
  width: 100px;
  height: 40px;
}
</style>
