import {
  getRequest,
  postRequest,
  deleteRequest
} from '@/libs/axios'

// 获取审核资讯信息详情
export const getAuditNewsDetail = (id) => {
  return getRequest(`/newsInfo/findNewsApplyInfo/${id}`)
}
// 资讯审核接口
export const auditNews = (data) => {
  return postRequest('/newsInfo/approve', data)
}
// 获取审核资讯信息列表
export const getAuditNewsList = (params) => {
  return getRequest('/newsInfo/getApplyByPage', params)
}
// 删除审核资讯
export const deleteAuditNews = (ids, params) => {
  return deleteRequest(`/newsInfo/deleteNewsApplyInfoByIds/${ids}`, params)
}
// 文本纠错接口
export const checkWord = (data) => {
  return postRequest('/newsInfo/checkWord', data, true)
}
// 资讯敏感词校验
export const checkSensitiveWord = (data) => {
  return postRequest('/newsInfo/checkSensitiveWord', data, true)
}
