<template>
  <div style="margin-bottom: 16px">
    <van-cell class="top-box">
      <div @click="handlePreview">
        <!-- 序号 -->
        <h4>{{ index+1 }}</h4>
        <!-- 标题区域 -->
        <div class="title-box">
          <!-- 单张图片 -->
          <img class="thumb" v-if="showType != 4" :src="headImg !== 'null'? headImg:imgurl.split(',')[0]" alt="封面图">
          <!-- 标题 -->
          <span class="title_info">{{ title }}</span>
        </div>
        <!--资讯类型区域-->
        <div class="label-box">
          <div class="time" style="margin-top: 3px">{{ pubdate }}</div>
          <div class="label" v-if="isOriginal == 1">原创</div>
          <div class="examine-label" v-if="isQuick == 1">快稿</div>
          <div class="type">资讯分类：{{typeName}}</div>
        </div>
        <!--资讯信息区域-->
        <div class="label-box">
          <div>{{organizationTitle}}</div>
          <div>采写：{{assignment}}</div>
          <div>编辑：{{name}}</div>
        </div>
      </div>
      <div class="line" v-if="role == 'ROLE_THIRD'"></div>
      <!-- 推荐和资讯启用按钮 -->
      <div class="function" v-if="role == 'ROLE_THIRD'">
        <div class="function_item">
          <h4> 是否推荐：</h4>
          <van-switch style="margin-top: 3px;" v-model="recommendValue" @click="checkedChange(id)" size="22px" :active-value="true" :inactive-value="false" />
        </div>
        <div class="function_item">
          <h4>是否启用：</h4>
          <van-switch style="margin-top: 3px;" v-model="headValue" @click="checkedChange2(id)" size="22px" :active-value="true" :inactive-value="false" />
        </div>
      </div>
      <!-- 阅读数 -->
      <div class="function-read">
        <h4 style="width: 70px;">阅读数：</h4>
        <!-- 允许输入数字，调起带符号的纯数字键盘 -->
        <van-field v-model="readNum" type="number" @blur="readNumChange(id,readNum)" :maxlength="10" center error size="15px" style="border: 1px solid #ccc; border-radius: 8%; width: 120px;" />
      </div>
      <!-- 原创稿件等级 -->
      <div class="function" v-if="role == 'ROLE_THIRD' && isOriginal == 1 ">
        <h4>原创稿件评级：</h4>
        <van-radio-group v-model="grade" direction="horizontal" @change="gradeChange(id,grade)">
          <van-radio name="A">A</van-radio>
          <van-radio name="B">B</van-radio>
          <van-radio name="C">C</van-radio>
          <van-radio name="D">D</van-radio>
        </van-radio-group>
      </div>
      <!-- <div class="label-box">
        <h4>综合分数：{{compositeScore == null && thirdApplyTime? '计算中' : compositeScore}}</h4>
      </div> -->
    </van-cell>
    <Modal v-model="previewModal" title="预览" :mask-closable="false" footer-hide>
      <detail-preview :previewData="previewData"></detail-preview>
    </Modal>
  </div>
</template>

<script>
import {
  updateNewsRecommend,
  updateNewsHead,
  updateGrade,
  updateNewsRead
} from '@/api/index'
import detailPreview from '@/components/detailPreview'
export default {
  name: 'newsList',
  components: {
    detailPreview
  },
  data() {
    return {
      recommendValue: false,
      headValue: false,
      previewModal: false,
      previewData: {}
    }
  },
  computed: {
    role() {
      return localStorage.role
    }
  },
  // 自定义属性
  props: {
    // 资讯的id
    id: {
      type: Number,
      default: 0
    },
    // 资讯的标题
    title: {
      type: String,
      default: ''
    },
    // 组织名称
    organizationTitle: {
      type: String,
      default: ''
    },
    // 资讯图片
    headImg: {
      type: String,
      default: ''
    },
    // 三图片资讯
    imgurl: {
      type: String,
      default: ''
    },
    // 资讯类型
    showType: {
      type: Number
    },
    // 采写人员
    assignment: {
      type: String,
      default: ''
    },
    // 编辑人员
    name: {
      type: String,
      default: '空'
    },
    // 阅读数
    readNum: {
      type: Number,
      default: 0
    },
    // 评级
    grade: {
      type: String,
      default: ''
    },
    // 终审通过时间
    thirdApplyTime: {
      type: String,
      default: ''
    },
    // 时间
    pubdate: {
      type: String,
      default: ''
    },
    // 类型
    typeName: {
      type: String,
      default: ''
    },
    // 是否审核
    hasApplyPermission: {
      type: Boolean
    },
    // 是否启用：1-启用 2-不启用
    isHead: {
      type: Number
    },
    // 是否快稿
    isQuick: {
      type: Number
    },
    // 是否原创
    isOriginal: {
      type: Number
    },
    // 是否推荐：1-是,2-否
    isRecommend: {
      type: Number
    },
    // 序号
    index: {
      type: Number
    },
    // 资讯内容
    modifyContent: {
      type: String,
      default: ''
    },
    // 视频资讯
    video: {
      type: String,
      default: ''
    }
  },
  created() {
    this.isRecommend === 1 ? this.recommendValue = true : this.recommendValue = false
    this.isHead === 1 ? this.headValue = true : this.headValue = false
    this.previewData.content = this.modifyContent
    this.previewData.showType = this.showType
    this.previewData.imgUrl = this.imgUrl
    this.previewData.videoUrl = this.video
    this.previewData.title = this.title
    this.previewData.createTime = this.pubdate
    this.previewData.coverImg = this.coverImg
  },
  methods: {
    // 修改阅读数
    readNumChange(id, readNum) {
      updateNewsRead({ id: id, readNum: readNum })
    },
    // 修改资讯评级
    gradeChange(id, grade) {
      updateGrade({ id: id, grade: grade })
    },
    // 封面预览
    handlePreview() {
      this.previewModal = true
    },
    edit() {
      const query = { id: this.id, backRoute: this.$route.name, checkFlag: 0 } // eslint-disable-line no-unused-vars
      // eslint-disable-next-line no-unused-vars, no-undef
      const params = { hasApplyPermission: this.hasApplyPermission }
      this.$router.push({
        name: 'passednews_edit',
        query: query,
        params: params
      })
    },
    checkedChange(id) {
      this.recommendValue = !this.recommendValue
      updateNewsRecommend({ id: id, isRecommend: this.recommendValue === true ? 1 : 2 })
    },
    checkedChange2(id) {
      this.headValue = !this.headValue
      updateNewsHead({ id: id, isHead: this.headValue === true ? 1 : 2 })
    }
  }

}
</script>

<style lang='less' scoped>
.top-box {
  width: 92%;
  background: #ffffff;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  border: 1px solid #eaf0f6;
  //margin-bottom: 16px;
  margin-left: 15px;
}
.label-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .time {
    height: 18px;
    font-size: 12px;
    font-weight: 500;
    color: #8d8d8d;
    line-height: 18px;
  }
  .label {
    width: 45px;
    height: 18px;
    background: #a32712;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    line-height: 18px;
  }
  .examine-label {
    width: 45px;
    height: 18px;
    background: #ff9671;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    line-height: 18px;
  }
}
.title-box {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .title_info {
    height: 72px;
    font-size: 15px;
    font-weight: 600;
    color: #030303;
    line-height: 15px;
    margin-left: 13px;
  }
}
.title-box span {
  width: 70%;
}
.line {
  height: 2px;
  margin-top: 12px;
  background-color: #b39cd0;
}
.newsbody {
  border: 1px solid #ccc;
  margin-top: 20px;
  height: 70px;
  overflow: hidden;
}
.body-img {
  height: 100%;
  width: 15%;
  float: left;
}
.thumb {
  width: 40%;
  height: 77px;
}
.content {
  height: 100%;
}
.content h6 {
  position: relative;
  top: -15%;
  left: 6%;
  display: inline-block;
}
.examine-label {
  height: 24px;
  width: 42px;
  line-height: 24px;
  display: inline-block;
  border: 1px solid #ccc;
  font-size: 10px;
  background-color: #ff6f91;
  text-align: center;
}
.label {
  height: 24px;
  width: 42px;
  line-height: 24px;
  display: inline-block;
  border: 1px solid #ccc;
  font-size: 10px;
  background-color: #ffcc00;
  text-align: center;
}
.information {
  position: relative;
  display: inline-block;
  top: -32%;
  left: 10%;
  line-height: 16px;
}
.information li {
  float: left;
  padding-left: 10px;
  font-size: 10px;
}
.function {
  display: flex;
  height: 30px;
  line-height: 30px;
  margin-bottom: 10px;
  justify-items: center;
  justify-content: space-between;
  text-align: center;
}
.function-read {
  display: flex;
  height: 30px;
  margin-bottom: 10px;
  line-height: 30px;
  justify-items: center;
  justify-content: flex-start;
  text-align: left;
}
.function_item {
  display: flex;
  justify-content: center;
  text-align: center;
  line-height: 30px;
}
.function span {
  font-size: 16px;
  text-align: center;
}
</style>
