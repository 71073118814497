<template>
  <div class="wrapper">
    <div class="container">
      <div class="header">
        <div class="left-content">
          <!--图片-->
          <van-image :src="'https://yzwb-snz.oss-cn-hangzhou.aliyuncs.com/www/shaonianzhi_logo.png'" fit="contain"></van-image>
          <div class="head-left">
            <div class="title-top">少年志APP</div>
            <div class="title-bottom">少年有志，未来无界</div>
          </div>
        </div>
        <van-button type="danger" class="open-btn">打开</van-button>
      </div>
      <div>
        <!--单图资讯-->
        <div>
          <img :src="previewData.imgUrl" style="height:220px" class="img" v-if="(previewData.showType == 1 || previewData.showType == 5) && previewData.imgUrl" />
          <img :src="'https://yzwb-snz.oss-cn-hangzhou.aliyuncs.com/www/news.png'" style="height:220px" class="img" v-if="(previewData.showType == 1 || previewData.showType == 5) && !previewData.imgUrl" />
        </div>
        <!--多图资讯-->
        <div style="height:220px" v-if="previewData.showType == 2">
          <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="(item ,index) in previewData.imgUrl.split(',')" :key="index">
              <img :src="item" style="height:220px;object-fit:cover" />
            </van-swipe-item>
          </van-swipe>
        </div>
        <!--视频资讯-->
        <div v-if="previewData.showType == 3" style="height:220px;text-align: center">
          <video :src="previewData.videoUrl" :poster="previewData.coverImg" controls style="width:100%;height:100%;object-fit: contain;"></video>
        </div>
        <!--详情区域-->
        <div class="content">
          <div class="title" style="font-size:19px;font-weight:bold;color: #130000;border-bottom: none;">
            {{ previewData.title }}
          </div>
          <div class="time">
            <div>{{ previewData.createTime }}</div>
            <div>{{ 'xxxxxx' }}人浏览</div>
          </div>
          <div class="user_message">
            <div style="display:flex;align-items:center;">
              <div class="user_img">
                <van-image round width="51px" height="51px" fit="cover" :src="this.organizationHeadPic" />
              </div>
              <div class="user_data">
                <h4>少年志-示例</h4>
              </div>
            </div>
            <van-button type="danger" size="small" round>
              <van-icon name="plus" />关注
            </van-button>
          </div>
          <van-divider :style="{
            color: '#EEEEEE',
            background: '#EEEEEE',
            margin: '20px 0'
          }" />
          <div v-html="previewData.content" class="content1"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'detailPreview',
  props: {
    previewData: {
      type: Object
    }
  },
  created() {
    console.log('11')
    console.log(this.previewData)
  },
  data() {
    return {
      organizationHeadPic: 'https://yzwb-snz.oss-cn-hangzhou.aliyuncs.com/1dd0106a5a9648598ceac0b71f001f0d.png'
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.wrapper {
  background-color: #999999;
}

.container {
  border: 1px solid gainsboro;
  background-color: #fff;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 17px;
    background-color: #fff;

    //修改最新的打开样式
    .van-button--danger {
      background-color: #fb4830 !important;
      border: 1px solid #fb4830 !important;
    }

    .left-content {
      display: flex;

      .van-image {
        width: 39px;
        height: 39px;
        margin-right: 10px;
      }
    }

    .head-left {
      .title-top {
        font-size: 16px;
        color: #130000;
        font-weight: bold;
      }

      .title-bottom {
        font-size: 10px;
        color: #999999;
      }
    }

    .open-btn {
      border-radius: 6px;
      height: 27px;
      width: 54px;
      font-size: 12px;
      padding: 0;
    }
  }
}
</style>
<style lang="less">
.ivu-modal-body {
  padding: 0 !important;
}
.pop_up {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: #000000;
  opacity: 0.78;
}

.head {
  padding: 0 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  background-color: #fff;
  position: fixed;
  top: 0;
  width: calc(100% - 32px);
  z-index: 9998;

  .left-content {
    display: flex;

    .van-image {
      width: 39px;
      height: 39px;
      margin-right: 10px;
    }
  }

  .head-left {
    .title-top {
      font-size: 16px;
      color: #130000;
      font-weight: bold;
    }

    .title-bottom {
      font-size: 10px;
      color: #999999;
    }
  }

  .open-btn {
    border-radius: 6px;
    height: 27px;
    width: 54px;
    font-size: 12px;
    padding: 0;
  }
}

.content ::v-deep img {
  min-width: 100%;
}

.img {
  width: 100%;
  max-width: 100% !important;
  object-fit: cover;
}

.content {
  padding: 17px 17px 0;
}

.time {
  color: #999999;
  font-size: 14px;
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
}

.user_message {
  margin-top: 20px;
  padding: 15px;
  background: #f9f9f9;
  // margin-top: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  border-radius: 9px;

  .user_img {
    position: relative;

    img {
      border-radius: 50%;
    }

    .tag {
      position: absolute;
      right: 6px;
      bottom: 0px;
    }
  }

  .user_data {
    margin-left: 9px;

    // margin-top: 5px;
    h4 {
      font-size: 16px;
      color: #130000;
    }
  }

  .van-image--round {
    border-radius: 6px;
  }

  .van-button--round {
    background-color: #fb4830;
    border-radius: 6px;
    padding: 0;
    width: 60px;
    height: 34px;

    .van-button__text {
      display: flex;
      align-items: center;

      .van-icon-plus {
        margin-right: 4px;
      }
    }
  }
}

#newsDetail_share {
  .special-area {
    padding: 0 17px;
    margin-bottom: 30px;
    margin-top: 20px;

    .topic-image {
      margin-bottom: 10px;
    }

    .van-image {
      width: 100%;
      height: 152px;
    }
  }

  .head-works {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .title {
      color: #1b0000;
      font-size: 19px;
      display: flex;

      .xiegan {
        height: 0;
        border-bottom: 3px solid #d0d0d0;
        width: 17px;
        border-radius: 3px;
        transform: rotate(135deg);
        margin-top: 11px;
      }

      .xiegan-dark {
        border-bottom: 3px solid #747474;
      }

      .work-title {
        color: #1b0000;
        font-size: 19px;
        margin-left: 7px;
        margin-right: 7px;
        font-weight: bold;
      }
    }
  }
}
</style>
<style lang="less">
.user_img {
  img {
    border-radius: 50%;
  }
}

.van-image-preview__overlay {
  background-color: rgba(0, 0, 0, 1);
}
.my-swipe .van-swipe-item {
  text-align: center;
  width: 100%;
}
</style>
