<template>
  <div @click="edit()" style="margin-bottom: 16px">
    <van-cell class="top-box">
      <!-- 标题区域 -->
      <div class="title-box">
        <!-- 单张图片 -->
        <img class="thumb" v-if="headImg" :src="headImg" alt="封面图">
        <!-- 标题 -->
        <span class="title_info">{{ title }}</span>
      </div>
      <!--资讯类型区域-->
      <div class="label-box">
        <div class="time" style="margin-top: 3px">{{ pubdate }}</div>
        <div class="label" v-if="isOriginal === 1">原创</div>
        <div class="examine-label" v-if="isQuick === 1">快稿</div>
        <div class="type">资讯分类：{{typeName}}</div>
      </div>
      <!--资讯信息区域-->
      <div class="label-box">
        <div>{{organizationTitle}}</div>
        <div>采写人员：{{assignment}}</div>
        <div>编辑人员：{{name}}</div>
      </div>
    </van-cell>
  </div>
</template>

<script>
export default {
  name: 'newsList',
  // 自定义属性
  props: {
    // 资讯的id
    id: {
      type: Number,
      default: 0
    },
    // 资讯的标题
    title: {
      type: String,
      default: ''
    },
    // 组织名称
    organizationTitle: {
      type: String,
      default: ''
    },
    // 资讯图片
    headImg: {
      type: String,
      default: ''
    },
    // 采写人员
    assignment: {
      type: String,
      default: ''
    },
    // 编辑人员
    name: {
      type: String,
      default: '空'
    },
    // 时间
    pubdate: {
      type: String,
      default: ''
    },
    // 类型
    typeName: {
      type: String,
      default: ''
    },
    // 是否审核
    hasApplyPermission: {
      type: Boolean
    },
    // 是否快稿
    isQuick: {
      type: Number
    },
    // 是否原创
    isOriginal: {
      type: Number
    }
  },
  methods: {
    edit() {
      const query = { id: this.id, backRoute: this.$route.name, checkFlag: 0 } // eslint-disable-line no-unused-vars
      // eslint-disable-next-line no-unused-vars, no-undef
      const params = { hasApplyPermission: this.hasApplyPermission }
      this.$router.push({
        name: 'news_edit',
        query: query,
        params: params
      })
    }
  }

}
</script>

<style lang='less' scoped>
.top-box {
  width: 92%;
  height: 160px;
  background: #ffffff;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  border: 1px solid #eaf0f6;
  //margin-bottom: 16px;
  margin-left: 15px;
}
.label-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .time {
    height: 18px;
    font-size: 12px;
    font-weight: 500;
    color: #8d8d8d;
    line-height: 18px;
  }
  .label {
    width: 45px;
    height: 18px;
    background: #a32712;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    line-height: 18px;
  }
  .examine-label {
    width: 45px;
    height: 18px;
    background: #ff9671;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    line-height: 18px;
  }
}
.title-box {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .title_info {
    height: 72px;
    font-size: 15px;
    font-weight: 600;
    color: #030303;
    line-height: 15px;
    margin-left: 13px;
  }
}
.title-box span {
  width: 70%;
}
.line {
  height: 2px;
  margin-top: 12px;
  background-color: #b39cd0;
}
.newsbody {
  border: 1px solid #ccc;
  margin-top: 20px;
  height: 70px;
  overflow: hidden;
}
.body-img {
  height: 100%;
  width: 15%;
  float: left;
}
.thumb {
  width: 40%;
  height: 77px;
}
.content {
  height: 100%;
}
.content h6 {
  position: relative;
  top: -15%;
  left: 6%;
  display: inline-block;
}
.examine-label {
  height: 24px;
  width: 42px;
  line-height: 24px;
  display: inline-block;
  border: 1px solid #ccc;
  font-size: 10px;
  background-color: #ff6f91;
  text-align: center;
}
.label {
  height: 24px;
  width: 42px;
  line-height: 24px;
  display: inline-block;
  border: 1px solid #ccc;
  font-size: 10px;
  background-color: #ffcc00;
  text-align: center;
}
.information {
  position: relative;
  display: inline-block;
  top: -32%;
  left: 10%;
  line-height: 16px;
}
.information li {
  float: left;
  padding-left: 10px;
  font-size: 10px;
}
</style>
