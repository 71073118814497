<style lang="less">
@import "./home.less";
</style>
<template>
  <div>
    <!--数据内容-->
    <div class="user-info">
      <div class="user-info-name">
        <img src="../../images/logo.4afa1218.png" alt="少年志">
        <div class-name="made-child-con-middle">
          <p class="info_name">{{ this.name }}</p>
          <h3 class="info_role">{{this.role}}</h3>
        </div>
      </div>
      <div>
        <div class-name="made-child-con-middle">
          <img class="avator-img" :src="this.name =='王雪瑞'? 'https://yzwb-snz.oss-cn-hangzhou.aliyuncs.com/18025db7c03daca844dcf5454d0d932.jpg' : avatarPath" />
        </div>
        <div class-name="made-child-con-middle" style="text-align: center">
          <button @click="loginOut" class="but">登出</button>
        </div>
      </div>
    </div>
    <!--下划线-->
    <div class="home_line"></div>
    <div class="affair-wrapper">
      <div class="info">
        <div class="info-title">个人代办</div>
        <div class="info-cell_wrapper">
          <!-- <div class="info-cell">
            <div class="info-cell_content">（{{backlogInfo.firstCheckNum}}）</div>
            <div class="info-cell_title">待初审资讯</div>
          </div> -->
          <div class="info-cell" @click="goNewsAuditListPage">
            <div class="info-cell_content">（{{ backlogInfo.recheckNum }}）</div>
            <div class="info-cell_title">待复审资讯</div>
          </div>
          <div class="info-cell" @click="goNewsAuditListPage">
            <div class="info-cell_content">（{{ backlogInfo.lastCheckNum }}）</div>
            <div class="info-cell_title">待终审资讯</div>
          </div>
        </div>
      </div>
      <!--下划线-->
      <div class="home_line"></div>
      <div class="info">
        <div class="info-title">近期审核通过资讯</div>
        <div class="info-cell_wrapper">
          <div class="info-cell">
            <div class="info-cell_content">（{{ backlogInfo.lastCheckNowNum }}）</div>
            <div class="info-cell_title">今日已通过</div>
          </div>
          <div class="info-cell">
            <div class="info-cell_content">（{{ backlogInfo.lastCheckThreeNum }}）</div>
            <div class="info-cell_title">3日内已通过</div>
          </div>
          <div class="info-cell">
            <div class="info-cell_content">（{{ backlogInfo.lastCheckWeekNum }}）</div>
            <div class="info-cell_title">本周已通过</div>
          </div>
          <div class="info-cell">
            <div class="info-cell_content">（{{ backlogInfo.lastCheckMonthNum }}）</div>
            <div class="info-cell_title">本月已通过</div>
          </div>
        </div>
      </div>
      <!--下划线-->
      <div class="home_line"></div>
      <div class="info">
        <div class="info-title">平台资讯总览</div>
        <div class="info-cell_wrapper">
          <div class="info-cell">
            <div class="info-cell_content">（{{ backlogInfo.totalNewsNum }}）</div>
            <div class="info-cell_title">平台总资讯数</div>
          </div>
          <div class="info-cell">
            <div class="info-cell_content">（{{ backlogInfo.passNewsNum }}）</div>
            <div class="info-cell_title">审核通过资讯</div>
          </div>
          <div class="info-cell">
            <div class="info-cell_content">（{{ backlogInfo.rejectNewsNum }}）</div>
            <div class="info-cell_title">审核未通过资讯</div>
          </div>
          <div class="info-cell">
            <div class="info-cell_content">（{{ backlogInfo.showNewsNum }}）</div>
            <div class="info-cell_title">启用中</div>
          </div>
          <div class="info-cell">
            <div class="info-cell_content">（{{ backlogInfo.closeNewsNum }}）</div>
            <div class="info-cell_title">未启用</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getBacklogInfo,
  getAuditNewsList
} from '@/api/index'

export default {
  name: 'home',
  data() {
    return {
      total: 0,
      backlogInfo: {
        recheckNum: null,
        lastCheckNum: null,
        firstCheckNum: null,
        lastCheckNowNum: null,
        lastCheckThreeNum: null,
        lastCheckWeekNum: null,
        lastCheckMonthNum: null,
        totalNewsNum: null,
        passNewsNum: null,
        rejectNewsNum: null,
        showNewsNum: null,
        closeNewsNum: null
      },
      // 表单搜索条件
      searchForm: {
        title: '',
        firstApplyStartTime: '',
        secondApplyStartTime: '',
        secondApplyEndTime: '',
        firstApplyEndTime: '',
        thirdApplyStartTime: '',
        thirdApplyEndTime: '',
        isPublish: '2',
        sort: '',
        order: '',
        pageNumber: 1
      }
    }
  },
  computed: {
    currNav() {
      return this.$store.state.app.currNav
    },
    avatarPath() {
      return localStorage.setAvatarPath
    },
    name() {
      return localStorage.name
    },
    role() {
      if (localStorage.role === 'ROLE_THIRD') {
        return '终审人员'
      } else if (localStorage.role === 'ROLE_SECOND') {
        return '复审人员'
      } else if (localStorage.role === 'ROLE_FIRST') {
        return '初审人员'
      } else {
        return '管理员'
      }
    }
  },
  mounted() {
    this.init()
    this.onGetBacklogInfo()
    // 设置背景颜色
    document.querySelector('body').setAttribute('style', 'background-color:#FFFFFF')
  },
  beforeDestroy() {
    document.querySelector('body').removeAttribute('style')
  },
  methods: {
    init() {
      const userInfo = JSON.stringify(this.getStore('userInfo'))
      console.log(userInfo)
      getAuditNewsList(this.searchForm).then(res => {
        if (res.success) {
          // 待审核资讯数
          this.total = res.result.total
        }
      })
    },
    onGetBacklogInfo() {
      getBacklogInfo().then(res => {
        console.log(res)
        if (res.message === 'success') {
          this.backlogInfo = res.result
        }
      })
    },
    loginOut() {
      // 退出登录
      this.removeStore('userInfo')
      this.removeStore('accessToken')
      this.removeStore('name')
      this.removeStore('setAvatarPath')
      this.removeStore('saveLogin')
      this.removeStore('role')
      // 强制刷新页面 重新加载router
      location.reload()
    },
    goNewsAuditListPage() {
      this.$router.push({
        name: 'unapproved'
      })
    }
  }
}
</script>
