<template>
  <div class="common-upload-image">
    <!-- <Poptip trigger="hover" title="图片预览" placement="right" class="imageInput" v-if="currentValue && isSupPreview">
      <Input v-model="currentValue" @on-change="handleChange" :placeholder="placeholder" :size="size" :disabled="disabled" :readonly="readonly" :maxlength="maxlength" clearable />
      <div slot="content">
        <img :src="currentValue" alt="无效的图片链接" style="width: 100%;margin: 0 auto;display: block;">
        <a @click="viewImage = true" style="margin-top:5px;text-align:right;display:block">查看大图</a>
      </div>
    </Poptip> -->
    <!-- <Input v-model="currentValue" @on-change="handleChange" :placeholder="placeholder" :size="size" :disabled="disabled" :readonly="readonly" :maxlength="maxlength" clearable /> -->
    <img style="width: 240px;height: 90px; margin-left: 10px;" :src="currentValue">
    <Upload :action="uploadFileUrl" :headers="accessToken" :on-success="handleSuccess" :on-error="handleError" :format="['jpg', 'jpeg', 'png', 'gif', 'bmp']" accept=".jpg, .jpeg, .png, .gif, .bmp" :max-size="5120" :on-format-error="handleFormatError" :on-exceeded-size="handleMaxSize" :show-upload-list="false" ref="up" class="upload">
      <Button :loading="loading" :size="size" :disabled="disabled" :icon="icon">{{ btnText }}</Button>
    </Upload>
    <!-- <Modal title="图片预览" v-model="viewImage" :styles="{ top: '30px' }" draggable>
      <img :src="currentValue" alt="无效的图片链接" style="width: 100%;margin: 0 auto;display: block;">
      <div slot="footer">
        <Button @click="viewImage = false">关闭</Button>
      </div>
    </Modal> -->
  </div>
</template>

<script>
import { getStore } from '@/libs/storage'
const accessToken1 = getStore('accessToken')
export default {
  name: 'uploadPicInput',
  props: {
    value: String,
    size: String,
    placeholder: {
      type: String,
      default: '请上传大小不超过5M的图片'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    maxlength: Number,
    icon: {
      type: String,
      default: 'ios-cloud-upload-outline'
    },
    btnText: {
      type: String,
      default: '上传'
    },
    isSupPreview: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      accessToken: {},
      currentValue: this.value,
      loading: false,
      viewImage: false,
      uploadFileUrl: '/admin/fm/upload/file'
    }
  },
  methods: {
    init() {
      this.accessToken = {
        accessToken: accessToken1
      }
    },
    handleFormatError(file) {
      this.loading = false
      this.$Notice.warning({
        title: '不支持的文件格式',
        desc:
          '所选文件‘ ' +
          file.name +
          ' ’格式不正确, 请选择 .jpg .jpeg .png .gif .bmp格式文件'
      })
    },
    handleMaxSize(file) {
      this.loading = false
      this.$Notice.warning({
        title: '文件大小过大',
        desc: '所选文件‘ ' + file.name + ' ’大小过大, 不得超过 5M.'
      })
    },
    // beforeUpload(file) {
    //   return this.checkImageWH(file, 690, 280)
    // },
    // checkImageWH  返回一个promise  检测通过返回resolve  失败返回reject组织图片上传
    // checkImageWH(file, width, height) {
    //   const self = this
    //   return new Promise(function (resolve, reject) {
    //     const filereader = new FileReader()
    //     filereader.onload = e => {
    //       const src = e.target.result
    //       const image = new Image()
    //       image.onload = function () {
    //         if (width && this.width !== width) {
    //           self.$Notice.error({
    //             title: '请上传宽为' + width + '的图片'
    //           })
    //           // eslint-disable-next-line prefer-promise-reject-errors
    //           reject()
    //         } else if (height && this.height !== height) {
    //           self.$Notice.error({
    //             title: '请上传高为' + height + '的图片'
    //           })
    //           // eslint-disable-next-line prefer-promise-reject-errors
    //           reject()
    //         } else {
    //           resolve()
    //         }
    //       }
    //       image.onerror = reject
    //       image.src = src
    //     }
    //     filereader.readAsDataURL(file)
    //   })
    // },
    handleSuccess(res, file) {
      this.loading = false
      if (res.success === true) {
        file.url = res.result
        this.currentValue = res.result
        this.$emit('input', this.currentValue)
        this.$emit('on-change', this.currentValue)
      } else {
        this.$Message.error(res.message)
      }
    },
    handleError(error, file, fileList) {
      this.loading = false
      this.$Message.error(error.toString())
    },
    handleChange(v) {
      this.$emit('input', this.currentValue)
      this.$emit('on-change', this.currentValue)
    },
    setCurrentValue(value) {
      if (value === this.currentValue) {
        return
      }
      this.currentValue = value
      this.$emit('on-change', this.currentValue)
    }
  },
  watch: {
    value(val) {
      this.setCurrentValue(val)
    }
  },
  created() {
    this.init()
  }
}
</script>

<style lang="less">
.common-upload-image {
  width: 100%;
  padding-bottom: 10px;
}
.imageInput {
  display: inline-block;
  width: 100%;
  position: relative;
  vertical-align: middle;
  line-height: normal;
  margin-right: 10px;
}

.upload {
  display: inline-block;
  line-height: 90px;
}

.item-label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 12px;
  color: #515a6e;
  line-height: 1;
  padding: 10px 12px 10px 0;
  box-sizing: border-box;
  width: 100px;
}
</style>
