
<template>
  <div style="display: flex;">
    <Poptip trigger="hover" title="视频预览" placement="right" class="imageInput">
      <Input v-model="currentValue" @on-change="handleChange" :placeholder="placeholder" :size="size" :disabled="disabled" :readonly="readonly" :maxlength="maxlength" clearable />
      <div slot="content">
        <img :src="currentValue" alt="无效的图片链接" style="width: 100%;margin: 0 auto;display: block;">
        <!-- <a @click="viewImage=true" style="margin-top:5px;text-align:right;display:block">查看大图</a> -->
      </div>
    </Poptip>
    <!-- <Button @click="uploadVisible=true" :loading="loading" :size="size" :disabled="disabled" :icon="icon">上传视频</Button> -->

    <!-- <Modal title="图片预览" v-model="viewImage" :styles="{top: '30px'}" draggable>
      <img :src="currentValue" alt="无效的图片链接" style="width: 100%;margin: 0 auto;display: block;">
      <div slot="footer">
        <Button @click="viewImage=false">关闭</Button>
      </div>
    </Modal> -->
    <!-- <Drawer title="文件上传" closable v-model="uploadVisible" width="500">
      <Upload :action="uploadHost" :data="uploadData" :on-success="handleSuccess" :on-error="handleError" accept=".mp4" :max-size="61440" :on-exceeded-size="handleMaxSize" :before-upload="beforeUpload" multiple type="drag" ref="up">
        <div style="padding: 20px 0">
          <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
          <p>点击这里或将文件拖拽到这里上传</p>
        </div>
      </Upload>
      <div class="drawer-footer">
        <Button @click="clearFiles">清空上传列表</Button>
      </div>
    </Drawer> -->
  </div>
</template>

<script>
import { getStore } from '@/libs/storage'
import { oss } from '@/api/oss'
const accessToken1 = getStore('accessToken')
export default {
  name: 'uploadPicInput',
  props: {
    value: String,
    size: String,
    placeholder: {
      type: String,
      default: '请上传大小不超过60M的视频'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    maxlength: Number,
    icon: {
      type: String,
      default: 'ios-cloud-upload-outline'
    }
  },
  data() {
    return {
      uploadVisible: false,
      accessToken: {},
      currentValue: this.value,
      loading: false,
      viewImage: false,
      // 附件上传路径
      uploadHost: '',
      // 附件上传携带参数
      uploadData: {},
      // 上传后返回的存储的文件名
      fileName: '',
      // 上传后返回的文件存储地址
      filePath: ''
    }
  },
  methods: {
    clearFiles() {
      this.$refs.up.clearFiles()
    },
    init() {
      console.log('111111' + accessToken1)
      this.accessToken = {
        accessToken: accessToken1
      }
    },
    handleFormatError(file) {
      this.loading = false
      this.$Notice.warning({
        title: '不支持的文件格式',
        desc:
          '所选文件‘ ' +
          file.name +
          ' ’格式不正确, 请选择 .jpg .jpeg .png .gif .bmp格式文件'
      })
    },
    handleMaxSize(file) {
      this.loading = false
      this.$Notice.warning({
        title: '文件大小过大',
        desc: '所选文件‘ ' + file.name + ' ’大小过大, 不得超过 60M.'
      })
    },
    beforeUpload(file) {
      this.loading = true
      const _this = this
      return oss(file.name).then(res => {
        _this.uploadHost = res.host
        _this.uploadData = res
        _this.filePath = res.host + '/' + res.key
      })
      // eslint-disable-next-line no-unreachable
      return true
    },
    handleSuccess(res, file) {
      console.log(res)
      this.loading = false
      file.url = this.filePath
      this.$emit('input', this.filePath)
      this.$emit('on-change', this.filePath)
      // if (res.success == true) {
      //   file.url = res.result;
      //   this.currentValue = res.result;

      // } else {
      //   this.$Message.error(res.message);
      // }
    },
    handleError(error, file, fileList) {
      this.loading = false
      this.$Message.error(error.toString())
    },
    handleChange(v) {
      this.$emit('input', this.currentValue)
      this.$emit('on-change', this.currentValue)
    },
    setCurrentValue(value) {
      if (value === this.currentValue) {
        return
      }
      this.currentValue = value
      this.$emit('on-change', this.currentValue)
    }
  },
  watch: {
    value(val) {
      this.setCurrentValue(val)
    }
  },
  created() {
    this.init()
  }
}
</script>

<style lang="less">
.imageInput {
  display: inline-block;
  width: 100%;
  position: relative;
  vertical-align: middle;
  line-height: normal;
  margin-right: 10px;
}
.upload {
  display: inline-block;
}
.item-label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 12px;
  color: #515a6e;
  line-height: 1;
  padding: 10px 12px 10px 0;
  box-sizing: border-box;
  width: 100px;
}
.ivu-input-icon {
  display: none;
}
.ivu-upload {
  display: inline-block;
}
.ivu-poptip {
  float: right;
  width: 100%;
}
</style>
