<template>
  <div class="body">
    <div class="search">
      <Row @keydown.enter.native="handleSearch">
        <Form ref="searchForm" :model="searchForm" inline :label-width="70" class="search-form">
          <Form-item label="标题" prop="organizationTitle" style="width: 60%">
            <Input type="text" v-model="searchForm.title" clearable placeholder="请输入名称" style="width: 100%" />
          </Form-item>
          <Form-item style="margin-left:-60px;" class="br">
            <Button @click="handleSearch" type="primary">搜索</Button>
            <Button @click="handleReset" style="margin-left: 15px">重置</Button>
          </Form-item>
        </Form>
      </Row>
    </div>
    <!--数据列表-->
    <newsList v-for="item in data" :key="item.id" :title="item.title" :organizationTitle="item.organizationTitle" :headImg="item.headImg" :name="item.editor" :pubdate="item.createTime" :typeName="item.typeName" :hasApplyPermission="item.hasApplyPermission" :id="item.id" :isQuick="item.isQuick"></newsList>
  </div>
</template>

<script>
// 导入组件
import newsList from '@/components/newsList.vue'
import {
  getAuditNewsList
} from '@/api/index.js'
export default {
  name: 'failed',
  // 注册组件
  components: {
    newsList
  },
  data() {
    return {
      // 筛选时间
      date: '',
      // 当前时间
      nowDate: '',
      show: false,
      title: '',
      lianmeng: '',
      currentPage: '',
      loading: true, // 表加载状态
      data: [], // 表单数据
      total: 0, // 表单数据总数
      // 表单搜索条件
      searchForm: {
        title: '',
        firstApplyStartTime: '',
        secondApplyStartTime: '',
        secondApplyEndTime: '',
        firstApplyEndTime: '',
        thirdApplyStartTime: '',
        thirdApplyEndTime: '',
        isPublish: '3',
        sort: '',
        order: '',
        pageNumber: 1,
        pageSize: 100
      }
    }
  },
  // watch: {
  //   $route(to, from) {
  //     console.log(from.name)
  //     if (from.name === 'news-edit' && to.name === 'Unapproved') {
  //       this.onGetAuditNewsList()
  //     }
  //   }
  // },
  mounted() {
    this.onGetAuditNewsList()
  },
  methods: {
    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`
    },
    onConfirm(date) {
      const [start, end] = date
      this.show = false
      // 时间区间
      this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`
      this.nowDate = `${this.formatDate(start)} - ${this.formatDate(end)}`
    },
    // 头部筛选区域表单
    onSubmit(values) {
      console.log('submit', values, this.date)
    },
    // 获取当前时间
    getNowDate() {
      // 年
      const year = new Date().getFullYear()
      // 月份是从0月开始获取的，所以要+1;
      const month = new Date().getMonth() + 1
      // 日
      const day = new Date().getDate()
      this.nowDate = year + '-' + month + '-' + day
    },
    // 搜索
    handleSearch() {
      this.searchForm.pageNumber = 1
      this.searchForm.pageSize = 10
      console.log(this.firstApplyTime)
      console.log(this.secondApplyTime)
      console.log(this.thirdApplyTime)
      console.log(this.searchForm)
      this.onGetAuditNewsList()
    },
    // 重置
    handleReset() {
      this.$refs.searchForm.resetFields()
      this.searchForm.title = ''
      this.searchForm.pageNumber = 1
      this.searchForm.pageSize = 100
      // 重新加载数据
      this.onGetAuditNewsList()
    },
    onGetAuditNewsList() {
      // 多条件搜索用户列表
      this.loading = true
      getAuditNewsList(this.searchForm).then(res => {
        this.loading = false
        if (res.success === true) {
          this.data = res.result.records
          this.total = res.result.total
          console.log(this.data)
        }
      })
    }
  }
}
</script>

<style lang='less' scoped>
.body {
  margin-bottom: 50px;
}
.search-form {
  display: flex;
  height: 100%;
}
.una-form {
  width: 100%;
  height: 100%;
  margin-top: 50px;
  display: inline-block;
}
.ivu-row {
  justify-content: flex-start; /* 左对齐 */
  flex-wrap: wrap; /* 换行 */
}
.ivu-col {
  margin-right: 20px; /* 每个元素右间距设置为20px */
}
.van-field {
  width: 20%;
  margin-left: 10px;
  float: left;
}
.van-button {
  width: 20%;
  float: left;
  margin-right: 10px;
  height: 44px;
  background-color: #fff;
  color: black;
  border-color: #cccccc;
}
.van-pagination {
  margin-top: 30px;
}
</style>
