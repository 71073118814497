import { getSignature } from '@/api/index'

let accessid = ''
let policyBase64 = ''
let signature = ''
// eslint-disable-next-line no-unused-vars
let callbackify = ''
let key = ''
// eslint-disable-next-line no-unused-vars
let expire = 0
let host = ''
// eslint-disable-next-line camelcase
let g_object_name = ''
// eslint-disable-next-line no-unused-vars
const now = Date.parse(new Date()) / 1000

// 生成随机字符串
// eslint-disable-next-line camelcase
function random_string(len) {
  len = len || 32
  var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
  var maxPos = chars.length
  var pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}
// 获取用户上传原始文件名
// eslint-disable-next-line camelcase
function get_file_name(filename) {
  const pos = filename.lastIndexOf('.')
  let suffix = ''
  if (pos !== -1) {
    suffix = filename.substring(pos)
  }
  return suffix
}
//  把随机生成的字符串拼接在原始上传文件名后面生成新的唯一文件名
// eslint-disable-next-line camelcase
function set_file_name(filename) {
  const suffix = get_file_name(filename)
  // eslint-disable-next-line camelcase
  g_object_name = key + random_string(10) + suffix
  return ''
}

// 获取后端返回的签名信息，生成oss参数
export const oss = (filename) => {
  // 可以判断当前expire是否超过了当前时间， 如果超过了当前时间， 就重新取一下， 3 s 作为缓冲。
  // 调用后端服务器接口获取签名信息，利用axios返回promise，可以链式调用
  return getSignature().then(result => {
    const res = result.result
    console.log(res)
    /* 返回的签名策略信息包含:
        {
          accessid: "LTAI*******UPPr", // 用户请求的accessid
          callback: "eyJjYWxs************H0ifQ==", // 回调
          dir: "test/file-dir/", // 上传文件的存储位置
          expire: "1557974779", // 上传策略Policy失效时间
          host: "http://xxxxxxxxx.com", // 上传文件服务器地址
          policy: "eyJleHBp***********6/EMG7U=" ,// 用户表单上传的策略（Policy)
          signature: "JumJy*****k6/EMG7U=" // 签名信息
        }
        */
    policyBase64 = res.policy
    accessid = res.accessid
    signature = res.signature
    expire = parseInt(res.expire)
    callbackify = res.callback
    host = res.host
    key = res.dir

    if (filename != null) {
      set_file_name(filename)
    }
    // 返回表单上传需要的参数信息
    return {
      host: host,
      key: g_object_name,
      policy: policyBase64,
      OSSAccessKeyId: accessid,
      success_action_status: '200', // 让服务端返回200,不然，默认会返回204
      //   'callback': callbackbody,
      signature: signature
    }
  })
}
