// 统一请求路径前缀在libs/axios.js中修改
import {
  getRequest,
  postRequest,
  deleteRequest
} from '@/libs/axios'
// 专题列表
export const getDataList = (params) => {
  return getRequest('/topic/queryPage', params)
}
// 删除
export const deleteData = (ids) => {
  return deleteRequest('/topic/delByIds/' + ids)
}
// 启用、禁用
export const updateState = (params) => {
  return postRequest('/topic/updateIsUsed', params)
}
// 推荐
export const updateRec = (params) => {
  return postRequest('/topic/updateIsRecommend', params)
}
// 置顶
export const updateTop = (params) => {
  return postRequest('/topic/updateIsTop', params)
}
// 保存
export const editData = (params) => {
  return postRequest('/topic/editData', params, true)
}
// 根据类型获取栏目内容
export const searchContent = (params) => {
  return getRequest('/topic/searchContent', params)
}
// 获取专题详情
export const getDetail = (params) => {
  return getRequest('/topic/detail', params)
}
