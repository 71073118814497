// 统一请求路径前缀在libs/axios.js中修改
import {
  getRequest,
  postRequest
} from '@/libs/axios'

// 文件上传接口
export const uploadFile = '/admin/fm/upload/file'

// //获取资讯管理列表数据
export const getNewsListData = (params) => {
  return getRequest('/newsInfo/getByPage', params)
}
// 用户登录
export const login = (params) => {
  return postRequest('/login', params)
}
// 获取用户登录信息
export const userInfo = (params) => {
  return getRequest('/user/info', params)
}
// 获取审核资讯信息列表
export const getAuditNewsList = (params) => {
  return getRequest('/newsInfo/getApplyByPage', params)
}
// 资讯是否启用
export const updateNewsHead = (params) => {
  return postRequest('/newsInfo/updateNewsHead', params)
}
// 更新阅读量
export const updateNewsRead = (params) => {
  return postRequest('/newsInfo/updateNewsRead', params)
}
// 更新资讯评分
export const updateGrade = (params) => {
  return postRequest('/newsInfo/updateNewsGrade', params)
}
// 获取审核资讯信息
export const getAuditNews = (id, params) => {
  return getRequest('/newsInfo/getApplyById', params)
}
export const findNewsInfo = (id, params) => {
  return getRequest(`/newsInfo/findNewsInfo/${id}`, params)
}
// 获取审核资讯信息详情
export const getAuditNewsDetail = (id) => {
  return getRequest(`/newsInfo/findNewsApplyInfo/${id}`)
}
// 获取app用户列表
export const getAppUserInfoList = (params) => {
  return getRequest('/userInfo/getUserInfoList', params)
}
export const isPublishActivity = (params) => {
  return postRequest('/activityInfo/isPublishActivity', params)
}
// 更新推荐标志
export const updateNewsRecommend = (params) => {
  return postRequest('/newsInfo/updateNewsRecommend', params)
}
// 修改资讯信息
export const updateNewsInfo = (params) => {
  return postRequest('/newsInfo/updateNewsInfo', params)
}
export const getNewsTypeList = (params) => {
  return getRequest('/newsInfo/getNewsTypeList', params)
}
// 获取组织列表
export const getOrganizationList2 = (params) => {
  return getRequest('/organizationinfo/getOrganizationList2', params)
}
// 首页待办信息查询
export const getBacklogInfo = () => {
  return getRequest('/newsInfo/getIndexInfo')
}
// 查看霸屏配置信息
export const getDominateScreenCfgInfo = () => {
  return getRequest('/bapingCfg/info')
}

// 编辑霸屏配置信息
export const editDominateScreenCfgInfo = (params) => {
  return postRequest('/bapingCfg/edit', params, true)
}

// 查看滑动卡片配置信息
export const getSlideCardCfgInfo = () => {
  return getRequest('/tCardInfo/info')
}

// 编辑滑动卡片配置信息
export const editSlideCardCfgInfo = (params) => {
  return postRequest('/tCardInfo/edit', params, true)
}
// 获取专题列表
export const getTopicList = (params) => {
  return getRequest('/topic/getTopicList', params)
}
