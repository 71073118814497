import Vue from 'vue'
import App from './App.vue'
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  importRequest,
  uploadFileRequest
} from '@/libs/axios'
// import iView, { Upload } from 'iview'

import { setStore, getStore, removeStore } from '@/libs/storage'
import router from './router'
import Vant from 'vant'
import 'vant/lib/index.less'
import iView from 'iview'
import 'iview/dist/styles/iview.css'
import 'view-design/dist/styles/iview.css'
import Vuex from 'vuex'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(Vuex)
Vue.use(iView)
Vue.use(ElementUI)

Vue.use(Vant)
// 挂载全局使用的方法
Vue.prototype.removeStore = removeStore
Vue.prototype.getRequest = getRequest
Vue.prototype.postRequest = postRequest
Vue.prototype.putRequest = putRequest
Vue.prototype.deleteRequest = deleteRequest
Vue.prototype.importRequest = importRequest
Vue.prototype.uploadFileRequest = uploadFileRequest
Vue.prototype.setStore = setStore
Vue.prototype.getStore = getStore
Vue.prototype.removeStore = removeStore
Vue.config.devtools = true
// Vue.config.devtools = true

// Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  data: {
    currentPageName: ''
  },
  mounted() {
    this.currentPageName = this.$route.name
    // 显示打开的页面的列表
    this.$store.commit('setOpenedList')
    this.$store.commit('initCachepage')
  }
}).$mount('#app')
