import Vue from 'vue'
import VueRouter from 'vue-router'
import pathArr from '@/router/pathArr.js'
// 登录组件
import Login from '@/views/Login/Login.vue'
// 概览
import Home from '@/views/Home/Home.vue'
// 待审核组件
import Unapproved from '@/views/Unapproved/Unapproved.vue'
// 已审核组件
import Reviewed from '@/views/Reviewed/Reviewed.vue'
// 霸屏组件
import Screen from '@/views/dominate-screen-manage/dominateScreenConfiguration.vue'
// 数据中心组件
import Datacenter from '@/views/DataCenter/DataCenter.vue'
// 已通过列表
import Passed from '@/components/passed.vue'
// 未通过列表
import Failed from '@/components/failed.vue'
// 资讯修改页
import NewsEdit from '@/components/news_edit.vue'
// 资讯列表修改页
import passedNewsEdit from '@/components/passednews_edit.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', name: 'login', component: Login, meta: { keepAlive: false } },
  { path: '/home', name: 'home', component: Home, meta: { keepAlive: true } },
  // 待审核
  { path: '/unapproved', name: 'unapproved', component: Unapproved, meta: { keepAlive: true } },
  // 已审核
  { path: '/reviewed', name: 'reviewed', component: Reviewed, redirect: '/reviewed/passed', meta: { keepAlive: true }, children: [{ path: 'passed', name: 'passed', component: Passed, meta: { keepAlive: true } }, { path: 'failed', name: 'failed', component: Failed, meta: { keepAlive: true } }] },
  // 数据中心
  { path: '/datacenter', name: 'datacenter', component: Datacenter, meta: { keepAlive: true } },
  // 资讯修改页
  { path: '/news_edit', name: 'news_edit', component: NewsEdit, meta: { keepAlive: true } },
  // 资讯列表修改页
  { path: '/passednews_edit', name: 'passednews_edit', component: passedNewsEdit, meta: { keepAlive: true } },
  // 霸屏配置
  { path: '/screen', name: 'screen', component: Screen, meta: { keepAlive: true } }
]

const router = new VueRouter({
  routes
})

router.beforeEach(function(to, form, next) {
  // 如果没有登录则必须要登录才能跳转主页
  console.log(pathArr.indexOf(to.path))
  if (pathArr.indexOf(to.path) !== -1) {
    const token = localStorage.getItem('accessToken')
    console.log(token)
    if (token) {
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }
})

export default router
