<template>
  <div>
    <!-- 路由占位符 -->
    <navigation v-if="!$route.meta.showNav"></navigation>
    <router-view></router-view>
    <!-- tabbar区域 -->
    <!-- 登录页不显示-->
    <van-tabbar route v-if="$route.meta.keepAlive">
      <van-tabbar-item replace to="/home" icon="gift-card">概览</van-tabbar-item>
      <van-tabbar-item v-if="this.role === 'ROLE_THIRD' || this.role === 'ROLE_SECOND'" replace to="/unapproved" icon="star">待审库</van-tabbar-item>
      <van-tabbar-item replace to="/reviewed" icon="checked">已审库</van-tabbar-item>
      <van-tabbar-item v-if="this.role === 'ROLE_THIRD'" replace to="/screen" icon="fire">霸屏配置</van-tabbar-item>
      <!-- <van-tabbar-item replace to="/datacenter" icon="fire-o">数据中心</van-tabbar-item> -->
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: 'APP',
  computed: {
    role() {
      return localStorage.role
    }
  }
}
</script>

<style lang='less' scoped>
</style>
