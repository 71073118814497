<template>
  <div class="rev_top">
    <!--子级路由链接-->
    <van-tabs type="card" color="#2d8cf0" style="margin-bottom: 16px;width:100%;" swipeable="true">
      <van-tab title="已通过列表" to="/reviewed/passed"></van-tab>
      <van-tab title="未通过列表" to="/reviewed/failed"></van-tab>
    </van-tabs>
    <!--子级路由占位符-->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'Reviewed'
}
</script>

<style lang='less' >
.van-tabs__nav--card {
  margin: 0 !important;
}
.rev_top {
  padding-bottom: 16px;
}
</style>
