<template>
  <div class="search">
    <Row>
      <Col>
      <Card>
        <div class="title">
          <div v-if="checkFlag == 0">
            <Button type="text" @click="closeCurrentPage">返回</Button>
            <Button type="info" @click="handlePreview">预览</Button>
            <Button type="info" @click="handleCheckText">文书纠错</Button>
            <Button type="warning" @click="showSensitiveWord" v-show="showSensitiveWordFlag">显示敏感词</Button>
            <Button type="warning" @click="hideSensitiveWord" v-show="!showSensitiveWordFlag">隐藏敏感词</Button>
            <Dropdown @on-click="handleDropdown" style="z-index: 1024;" v-show="hasApplyPermission">
              <Button type="primary">
                请选择
                <Icon type="arrow-down-b"></Icon>
              </Button>
              <Dropdown-menu slot="list" style="text-align: center;z-index: 1024;">
                <Dropdown-item name="handlePass">审核通过</Dropdown-item>
                <Dropdown-item name="handleNoPass">审核不通过</Dropdown-item>
              </Dropdown-menu>
            </Dropdown>
          </div>
          <Button type="text" @click="closeCurrentPage" v-if="checkFlag != 0">返回</Button>
        </div>
        <Row>
          <Form ref="newsForm" :model="newsForm" :label-width="100" inline :disabled="checkFlag != 0">
            <Col span="10">
            <div class="audit-wrapper">
              <div class="audit-title">审核状态</div>
              <div class="audit-img">
                <img v-if="newsForm.status == 4 && newsForm.isPublish == 3" src="@/assets/nopass.png" />
                <img v-if="newsForm.status == 4 && newsForm.isPublish == 1" src="@/assets/pass.png" />
                <img v-if="(newsForm.status == 1 || newsForm.status == 2 || newsForm.status == 3) && newsForm.isPublish == 2" src="@/assets/pending.png" />
              </div>
              <div class="audit-status">
                <span v-if="newsForm.status == 1 && newsForm.isPublish == 2">待初审</span>
                <span v-if="newsForm.status == 2 && newsForm.isPublish == 2">待复审</span>
                <span v-if="newsForm.status == 3 && newsForm.isPublish == 2">待终审</span>
                <span v-if="newsForm.status == 4 && newsForm.isPublish == 1">审核通过</span>
                <span v-if="newsForm.status == 4 && newsForm.isPublish == 3">审核不通过</span>
              </div>
              <!-- 非快稿审核流程 -->
              <div class="timeline-wrapper" v-if="newsForm.isQuick != 1">
                <Timeline>
                  <!-- 初审 -->
                  <TimelineItem>
                    <div slot="dot" class="dot1">1</div>
                    <div class="title1">初审通过</div>
                    <div class="audit-info">
                      <span>资讯ID：{{ hisList[0].newsId }}</span>
                      <span>创建联盟号：{{ hisList[0].username }}</span>
                    </div>
                    <div class="audit-info">
                      <span>初审时间：{{ hisList[0].createTime }}</span>
                      <span>联盟号：{{ hisList[0].orgName }}</span>
                    </div>
                    <div class="audit-info">
                      <span>初审人：{{ this.newsForm.editor || "未填写"}}</span>
                    </div>
                  </TimelineItem>
                  <!-- 初审 -->
                  <!-- <TimelineItem>
                    <div slot="dot" class="dot1" v-if="hisList.length > 2">2</div>
                    <div slot="dot" class="dot2" v-if="hisList.length == 2">2</div>
                    <div slot="dot" class="dot3" v-if="hisList.length < 2">2</div>
                    <div :class="hisList.length === 2 ? 'title2' : 'title1'" v-if="hisList.length >= 2">{{
                      hisList[1].status | auditStatus }}
                    </div>
                    <div class="title3" v-else>初审</div>
                    <span class="audit-person" v-if="hisList.length == 2 && newsForm.status != 4 && hisList[1].status == 7">推荐初审人：{{
                        hisList[1].username
                      }}</span>
                    <div class="audit-card" v-if="hisList.length >= 2 && hisList[1].status != 7">
                      <el-card class="box-card">
                        <div slot="header" class="clearfix">
                          <span>初审人：{{ hisList[1].username }}</span>
                          <span>初审时间：{{ hisList[1].createTime }}</span>
                        </div>
                        <span class="opinion">初审意见：{{ hisList[1].operator }}</span>
                      </el-card>
                    </div>
                  </TimelineItem> -->
                  <!-- 复审 -->
                  <TimelineItem>
                    <div slot="dot" class="dot1" v-if="hisList.length > 2">2</div>
                    <div slot="dot" class="dot2" v-if="hisList.length == 2">2</div>
                    <div slot="dot" class="dot3" v-if="hisList.length < 2">2</div>
                    <div :class="hisList.length === 2 ? 'title2' : 'title1'" v-if="hisList.length >= 2">{{
                      hisList[1].status
                      | auditStatus }}
                    </div>
                    <div class="title3" v-else>复审</div>
                    <!-- <span class="audit-person" v-if="hisList.length == 2 && newsForm.status != 4 && hisList[1].status == 8">推荐复审人：{{
                        hisList[1].username }}</span> -->
                    <div class="audit-card" v-if="hisList.length >= 2 && hisList[1].status != 8">
                      <el-card class="box-card">
                        <div slot="header" class="clearfix">
                          <span>复审人：{{ hisList[1].username }}</span>
                          <span>复审时间：{{ hisList[1].createTime }}</span>
                        </div>
                        <span class="opinion">复审意见：{{ hisList[1].operator }}</span>
                      </el-card>
                    </div>
                  </TimelineItem>
                  <!-- 终审 -->
                  <TimelineItem>
                    <div slot="dot" class="dot1" v-if="hisList.length > 3">3</div>
                    <div slot="dot" class="dot2" v-if="hisList.length == 3">3</div>
                    <div slot="dot" class="dot3" v-if="hisList.length < 3">3</div>
                    <div :class="hisList.length === 3 ? 'title2' : 'title1'" v-if="hisList.length >= 3">{{
                      hisList[2].status | auditStatus }}
                    </div>
                    <div class="title3" v-else>终审</div>
                    <!-- <span class="audit-person" v-if="hisList.length == 3 && newsForm.status != 4 && hisList[2].status == 9">推荐终审人：{{
                        hisList[2].username }}</span> -->
                    <div class="audit-card" v-if="hisList.length == 3 && hisList[2].status != 9">
                      <el-card class="box-card">
                        <div slot="header" class="clearfix">
                          <span>终审人：{{ hisList[2].username }}</span>
                          <span>终审时间：{{ hisList[2].createTime }}</span>
                        </div>
                        <span class="opinion">终审意见：{{ hisList[2].operator }}</span>
                      </el-card>
                    </div>
                  </TimelineItem>
                </Timeline>
              </div>
              <!-- 快稿审核流程 -->
              <div class="timeline-wrapper" v-if="newsForm.isQuick == 1">
                <Timeline>
                  <!-- 创建 -->
                  <TimelineItem>
                    <div slot="dot" class="dot1">1</div>
                    <div class="title1">创建</div>
                    <div class="audit-info">
                      <span>资讯ID：{{ hisList[0].newsId }}</span>
                      <span>创建人：{{ hisList[0].username }}</span>
                    </div>
                    <div class="audit-info">
                      <span>创建时间：{{ hisList[0].createTime }}</span>
                      <span>联盟号：{{ hisList[0].orgName }}</span>
                    </div>
                  </TimelineItem>
                  <!-- 终审 -->
                  <TimelineItem>
                    <div slot="dot" class="dot1" v-if="hisList.length > 2">2</div>
                    <div slot="dot" class="dot2" v-if="hisList.length == 2">2</div>
                    <div slot="dot" class="dot3" v-if="hisList.length < 2">2</div>
                    <div :class="hisList.length === 2 ? 'title2' : 'title1'" v-if="hisList.length >= 2">{{
                      hisList[1].status | quickAuditStatus }}
                    </div>
                    <div class="title3" v-else>终审</div>
                    <!-- <span class="audit-person" v-if="hisList.length == 2 && newsForm.status != 4 && hisList[1].status == 9">推荐终审人：{{
                        hisList[1].username }}</span> -->
                    <div class="audit-card" v-if="hisList.length == 2 && hisList[1].status != 9">
                      <el-card class="box-card">
                        <div slot="header" class="clearfix">
                          <span>终审人：{{ hisList[1].username }}</span>
                          <span>终审时间：{{ hisList[1].createTime }}</span>
                        </div>
                        <span class="opinion">终审意见：{{ hisList[1].operator }}</span>
                      </el-card>
                    </div>
                  </TimelineItem>
                </Timeline>
              </div>
            </div>
            <!-- 基本信息 -->
            <div class="info-wrapper">
              <div class="basic-title">基本信息</div>
              <FormItem label="资讯名称" prop="title" style="width:100%;margin-right: 0;">
                <Input v-model="newsForm.title" autocomplete="off" class="input1Search" />
              </FormItem>
              <FormItem label="阅读数" prop="readNum" style="width:100%;margin-right: 0;">
                <InputNumber v-model="newsForm.readNum" type="text" autocomplete="off" class="input1Search" />
              </FormItem>
              <FormItem label="类型" prop="typeId" style="width:100%;margin-right: 0;">
                <Select v-model="newsForm.typeId" placeholder="请选择" class="input1Search">
                  <Option v-for="item in newsTypeList" :value="item.id" :key="item.id">{{ item.title }}</Option>
                </Select>
              </FormItem>
              <FormItem label="是否推荐" prop="isRecommend" style="width:100%;margin-right: 0;">
                <Select v-model="newsForm.isRecommend" placeholder="请选择" class="input1Search">
                  <Option :value="1">推荐</Option>
                  <Option :value="2">不推荐</Option>
                </Select>
              </FormItem>
              <FormItem label="选择联盟号" prop="organizationId" style="width:100%;margin-right: 0;">
                <Select v-model="newsForm.organizationId" placeholder="请选择" style="width:250px">
                  <Option v-for="(item, i) in organizationList" :key="i" :value="item.id">{{ item.title }}</Option>
                </Select>
              </FormItem>
              <FormItem label="资讯展示类型" prop="showType" style="width:100%;margin-right: 0;">
                <Select @on-change="hideInput(newsForm.showType)" v-model="newsForm.showType" placeholder="请选择" class="input1Search">
                  <Option :selected="selected" :value="1">单图下方资讯</Option>
                  <Option :value="2">三图片资讯</Option>
                  <Option :value="3">视频资讯</Option>
                  <Option :value="4">无图资讯</Option>
                  <Option :value="5">单图左边资讯</Option>
                </Select>
              </FormItem>
              <!-- <Form-item v-if="imgshow" label="资讯缩略图" prop="headImg" style="width:100%;margin-right: 0px">
                <template>
                  <upload-pic-input v-model="newsForm.headImg" style="width:100%;margin-bottom:20px;" ref="upload"></upload-pic-input>
                </template>
              </Form-item> -->
              <Form-item v-if="imgshow1" label="资讯图片一" prop="imgurl1" style="width:100%;margin-right: 0px">
                <template>
                  <upload-pic-input v-model="newsForm.imgurl1" style="width:100%;margin-bottom:20px;" ref="upload"></upload-pic-input>
                </template>
              </Form-item>
              <Form-item v-if="imgshow2" label="资讯图片二" prop="imgurl2" style="width:100%;margin-right: 0px">
                <template>
                  <upload-pic-input v-model="newsForm.imgurl2" style="width:100%;margin-bottom:20px;" ref="upload"></upload-pic-input>
                </template>
              </Form-item>
              <Form-item v-if="imgshow3" label="资讯图片三" prop="imgurl3" style="width:100%;margin-right: 0px">
                <template>
                  <upload-pic-input v-model="newsForm.imgurl3" style="width:100%;margin-bottom:20px;" ref="upload"></upload-pic-input>
                </template>
              </Form-item>
              <Form-item label="视频" v-if="videoshow" prop="videoUrl" style="width:100%;margin-right: 0px;display: inline-block;">
                <upload-vedio-input v-model="newsForm.videoUrl" class="input1Search" ref="uploadVedio"></upload-vedio-input>
              </Form-item>
            </div>
            </Col>
            <div class="basic-title">编辑器</div>
            <Col span="14">
            <div id="Test" style="width: 350px">
              <vue-ueditor-wrap v-model="modifyContent" :config="myConfig" id="randomId" />
            </div>
            <input type="file" name="file" ref="fileUpload" @change="upload_photo($event)" class="face" accept="image/*" />
            </Col>
          </Form>
        </Row>
      </Card>
      </Col>
    </Row>
    <Modal v-model="checkTextModal" fullscreen title="文书纠错系统">
      <div class="modal-content">
        <div class="modal-info">
          <div class="modal-info_title">
            <div class="modal-info_title_cell" style="color: #D9001B;">
              <span>全部疑似错误：</span>
              <span>{{ proposeModifyTextArr.length }}</span>
            </div>
            <div class="modal-info_title_cell" style="color: #AAAAAA;">
              <span>忽略数量：</span>
              <span>{{ ignoreFlagTotal }}</span>
            </div>
          </div>
          <div class="line"></div>
          <div v-for="(item, index) in proposeModifyTextArr" :key="index" class="text-list">
            <el-card class="box-card" v-if="!item.ignoreFlag">
              <div slot="header" class="clearfix">
                <span class="text-index">疑似错误{{ index + 1 }}</span>
                <Button type="error" size="small" slot-scope="" style="float: right" @click="handleIgnore(index)">已处理</Button>
                <!-- <Button style="float: right; padding: 3px 0;color: #02A7F0;margin-right: 16px;" type="text" @click="handlePosition(index)">定位</Button> -->
              </div>
              <div class="modify-text item">
                <div class="err-text">
                  <span>出错文本：</span>
                  <span>{{ item.sentence }}</span>
                </div>
                <div class="amend-text">
                  <span>订正文本：</span>
                  <span>{{ item.sentenceFixed }}</span>
                </div>
                <div class="explain">
                  <span>建议：</span>
                  <span>{{ item.explain }}</span>
                </div>
              </div>
            </el-card>
          </div>
        </div>
        <div class="modal-text">
          <vue-ueditor-wrap v-model="modifyTextContent" @ready="editorReady" :config="myConfig" id="modalEditor" />
        </div>
      </div>
      <div slot="footer">
        <Button type="text" @click="checkTextModal = false">取消</Button>
        <Button type="primary" :loading="auditSubmitLoading" @click="confirmSaveText">保存</Button>
      </div>
    </Modal>
    <Modal v-model="auditOperatorModal" :title="auditOperatorModalTitle" :mask-closable="false">
      <Input v-model="newsForm.operator" placeholder="请输入审核意见" class="input1Search" />
      <div slot="footer">
        <Button type="text" @click="auditOperatorModal = false">取消</Button>
        <Button type="primary" :loading="auditSubmitLoading" @click="auditOperatorModalOk">提交</Button>
      </div>
    </Modal>
    <Modal v-model="previewModal" title="预览" :mask-closable="false" footer-hide>
      <detail-preview :previewData="previewData"></detail-preview>
    </Modal>
  </div>
</template>
<script>
import { findNewsInfo, getNewsTypeList, getOrganizationList2 } from '@/api/index'
import { checkWord, checkSensitiveWord, auditNews } from '@/api/news.js'
import detailPreview from '@/components/detailPreview'
import uploadPicInput from '@/components/upload-pic-input'
import uploadVedioInput from '@/components/upload-vedio-input'
import VueUeditorWrap from 'vue-ueditor-wrap'
export default {
  name: 'passednews_edit',
  components: {
    detailPreview,
    uploadPicInput,
    uploadVedioInput,
    VueUeditorWrap
  },
  data() {
    return {
      checkFlag: 1,
      newsTypeList: [],
      organizationList: [],
      hisList: [
        {
          newsId: '',
          operator: '',
          orgName: '',
          status: null,
          username: '',
          createTime: ''
        }
      ],
      auditStatus: null,
      isPublish: '',
      newsForm: {
        readNum: '',
        organizationId: '',
        content: '',
        id: '',
        title: '',
        description: '',
        isRecommend: '',
        typeId: '',
        isPublish: '',
        videoUrl: '',
        imgurl3: '',
        imgurl2: '',
        imgurl1: '',
        headImg: '',
        showType: '',
        operator: ''
      },
      reqForm: {},
      previewModal: false,
      previewData: {},
      modifyContent: '',
      sensitiveWords: '',
      showSensitiveWordFlag: 1,
      checkTextModal: false,
      modifyTextContent: '',
      proposeModifyTextArr: [],
      sensitiveWordsArr: [],
      auditOperatorModal: false,
      auditOperatorModalTitle: '',
      auditSubmitLoading: false,
      editor: {},
      myConfig: {
        // 编辑器不自动被内容撑高
        autoHeightEnable: false,
        // 初始容器高度
        initialFrameHeight: 350,
        // 初始容器宽度
        initialFrameWidth: '100%',
        // 上传文件接口（暂未考虑）
        serverUrl: '/fm/upload/file',
        // vue-cli2中UE所在目录
        UEDITOR_HOME_URL: '/UE/',
        // 限制内容长度
        maximumWords: 10000
      },
      editorFile: '',
      videoshow: false,
      imgshow: false,
      imgshow1: false,
      imgshow2: false,
      imgshow3: false
    }
  },
  mounted() {
    this.init()
  },
  computed: {
    ignoreFlagTotal() {
      return this.proposeModifyTextArr.reduce((pre, item) => {
        return pre + (item.ignoreFlag ? 1 : 0)
      }, 0)
    }
  },
  filters: {
    // 时间线流程状态
    auditStatus(status) {
      const auditStatusMap = {
        0: '创建',
        1: '初审不通过',
        2: '初审通过',
        3: '复审不通过',
        4: '复审通过',
        5: '终审不通过',
        6: '终审通过',
        7: '待初审',
        8: '待复审',
        9: '待终审'
      }
      return auditStatusMap[status]
    },
    // 快稿时间线流程状态
    quickAuditStatus(status) {
      const auditStatusMap = {
        0: '创建',
        5: '终审不通过',
        6: '终审通过',
        9: '待终审'
      }
      return auditStatusMap[status]
    }
  },
  watch: {
    $route(to, from) {
      console.log(from.name)
      if (from.name === 'news-manage' && to.name === 'news-edit') {
        this.init()
      }
    }
  },
  methods: {
    init() {
      console.log(this.$route.query.backRoute)
      this.backRoute = this.$route.query.backRoute
      this.newsForm.id = this.$route.query.id
      this.hasApplyPermission = this.$route.params.hasApplyPermission
      console.log('id', this.newsForm.id)
      this.checkFlag = this.$route.query.checkFlag
      console.log('checkFlag', this.$route.query.checkFlag)
      this.getNewsTypeOptions()
      this.getOrganizationOptions()
      this.onGetAuditNewsDetail()
    },
    // 封面预览
    handlePreview() {
      this.previewData.content = this.modifyContent
      console.log(this.previewData)
      this.previewModal = true
    },
    // 文书纠错
    handleCheckText() {
      this.modifyTextContent = this.modifyContent
      const reqData = {
        content: this.modifyContent
      }
      // eslint-disable-next-line no-undef
      checkWord(reqData).then(res => {
        if (res.message === 'success') {
          this.proposeModifyTextArr = res.result || []
          if (this.proposeModifyTextArr.length > 0) {
            let re
            let str
            this.proposeModifyTextArr.forEach((item, index) => {
              this.$set(item, 'ignoreFlag', false)
              if (item.sentence.length > 4) {
                str = item.sentence.slice(0, 4)
              } else {
                str = item.sentence
              }
              re = new RegExp(str, 'g')
              if (re.test(this.modifyTextContent)) {
                // eslint-disable-next-line no-useless-escape
                this.modifyTextContent = this.modifyTextContent.replace(re, `<span id="${'errorText' + index}" style=\"border-bottom:2px solid red;\">${str}</span>`)
              }
            })
          }
        }
      })
      this.checkTextModal = true
    },
    // 忽略错误
    handleIgnore(index) {
      this.$set(this.proposeModifyTextArr[index], 'ignoreFlag', true)
      let re
      let str
      if (this.proposeModifyTextArr[index].sentence.length > 4) {
        str = this.proposeModifyTextArr[index].sentence.slice(0, 4)
      } else {
        str = this.proposeModifyTextArr[index].sentence
      }
      // eslint-disable-next-line prefer-const, no-useless-escape
      re = new RegExp(`<span id="${'errorText' + index}" style=\"border-bottom:2px solid red;\">${str}</span>`, 'g')
      if (re.test(this.modifyTextContent)) {
        this.modifyTextContent = this.modifyTextContent.replace(re, str)
      }
    },
    // 定位错误
    handlePosition(index) {
      const iframeDocument = document.getElementsByTagName('iframe')[1].contentDocument
      if (iframeDocument.getElementById(`errorText${index}`)) {
        iframeDocument.getElementById(`errorText${index}`).scrollIntoView()
      } else {
        this.$Message.warning('未定位成功，请手动查找')
      }
    },
    // 显示敏感词
    showSensitiveWord() {
      const reqData = {
        content: this.modifyContent
      }
      // eslint-disable-next-line no-undef
      checkSensitiveWord(reqData).then(res => {
        console.log('21321', res.result)
        if (res.result.length > 0) {
          this.sensitiveWordsArr = res.result
          let re
          this.sensitiveWordsArr.forEach((item) => {
            re = new RegExp(item, 'g')
            if (re.test(this.modifyContent)) {
              this.modifyContent = this.modifyContent.replace(re, '<span style="background-color: #EC808D80;">' + item + '</span>')
            }
          })
          this.showSensitiveWordFlag = 0
        } else {
          this.$Message.success('该文章没有敏感词')
        }
      })
    },
    // 隐藏敏感词
    hideSensitiveWord() {
      if (this.sensitiveWordsArr.length > 0) {
        let re
        this.sensitiveWordsArr.forEach((item) => {
          re = new RegExp('<span style="background-color: #EC808D80;">' + item + '</span>', 'g')
          if (re.test(this.modifyContent)) {
            this.modifyContent = this.modifyContent.replace(re, item)
          }
        })
      }
      this.showSensitiveWordFlag = 1
    },
    // 保存纠错之后的text
    confirmSaveText() {
      this.modifyContent = this.modifyTextContent
      // this.previewData.content = this.modifyContent
      this.checkTextModal = false
    },
    // 下拉选择
    handleDropdown(name) {
      if (name === 'handlePass') {
        this.handelSubmitAudit(1, '确定资讯审核通过吗?')
      } else if (name === 'handleNoPass') {
        this.handelSubmitAudit(3, '确定资讯审核不通过吗?')
      }
    },
    // 关闭当前页面
    closeCurrentPage() {
      console.log('上层路由', this.backRoute)
      this.$router.push({
        name: this.backRoute
      })
    },
    // 审核处理
    handelSubmitAudit(isPublish, textCon) {
      this.isPublish = isPublish
      this.auditOperatorModalTitle = textCon
      this.auditOperatorModal = true
    },
    // 确认提交审核
    auditOperatorModalOk() {
      console.log(this.newsForm)
      this.auditSubmitLoading = true
      this.hideSensitiveWord()
      this.newsForm.content = this.modifyContent
      const reqData = JSON.parse(JSON.stringify(this.newsForm))
      reqData.isPublish = this.isPublish
      console.log(reqData)
      // eslint-disable-next-line no-undef
      auditNews(reqData).then(res => {
        this.$Message.success(res.message)
        this.auditOperatorModal = false
      }).catch(() => {
        this.$Message.warning('操作失败')
      }).finally(() => {
        this.auditSubmitLoading = false
        this.closeCurrentPage()
      })
    },
    hideInput(v) {
      if (v === 1) {
        this.imgshow = true
        this.imgshow1 = false
        this.imgshow2 = false
        this.imgshow3 = false
        this.videoshow = false
      }
      if (v === 2) {
        this.imgshow = false
        this.imgshow1 = true
        this.imgshow2 = true
        this.imgshow3 = true
        this.videoshow = false
      }
      if (v === 3) {
        this.imgshow = true
        this.imgshow1 = false
        this.imgshow2 = false
        this.imgshow3 = false
        this.videoshow = true
      }
      if (v === 4) {
        this.imgshow = false
        this.imgshow1 = false
        this.imgshow2 = false
        this.imgshow3 = false
        this.videoshow = false
      }
      if (v === 5) {
        this.imgshow = true
        this.imgshow1 = false
        this.imgshow2 = false
        this.imgshow3 = false
        this.videoshow = false
      }
    },
    insertImage2BtnOnClick(editor) {
      this.$refs.fileUpload.click()
      this.editorFile = editor
    },
    upload_photo(event) {
      var file = event.target.files
      this.fileUploadFunction(file)
    },
    fileUploadFunction(file) {
      const _this = this
      // eslint-disable-next-line no-undef
      const accessToken = getStore('accessToken')
      const formData = new FormData() // 创建form对象
      formData.append('file', file[0])
      const xhr = new XMLHttpRequest()
      xhr.open('post', '/fm/upload/file', true)
      xhr.setRequestHeader('accessToken', accessToken)
      xhr.send(formData)
      // eslint-disable-next-line space-before-function-paren
      xhr.onload = function () {
        console.log(xhr.responseText) // 获取后台返回的数据
        // 返回图片后显示到编辑器
        const dateDto = JSON.parse(xhr.responseText)
        if (!dateDto.success) {
          this.$Message.warning(dateDto.message)
          return
        }
        const url = dateDto.result
        _this.editorFile.execCommand('insertimage', {
          src: url
        })
      }
    },
    // 获取类型options
    getNewsTypeOptions() {
      getNewsTypeList().then(res => {
        if (res.success === true) {
          console.log(res.result)
          this.newsTypeList = res.result.data
        }
      })
    },
    // 获取联盟options
    getOrganizationOptions() {
      getOrganizationList2(this.form).then(res => {
        this.organizationList = res
      })
    },
    // 获取审核资讯详情
    onGetAuditNewsDetail() {
      findNewsInfo(this.newsForm.id).then(res => {
        this.loading = false
        if (res.success) {
          // 转换null为""
          const v = res.result
          for (const attr in v) {
            if (v[attr] == null) {
              v[attr] = ''
            }
          }

          const str = JSON.stringify(v)
          const data = JSON.parse(str)
          console.log(data)
          this.newsForm = data
          this.imgUrl = data.imgUrl
          this.imgurl1 = data.imgUrl1
          this.imgurl2 = data.imgurl2
          this.imgurl3 = data.imgurl3
          this.videoUrl = data.videoUrl
          this.picUrl = data.imgUrl
          this.picSuoUrl = data.headImg
          this.content = data.content
          //  this.selectDate = data.bizDateStr;
          //  this.newsForm.bizDateStr = data.bizDateStr;
          // this.$refs.easSellProject.setSearchData(data.projectId);
          // this.$refs.easBuilding.setSearchData(data.projectId);
          // this.$refs.easBuildUnit.setSearchData(data.buildingIds);
          // this.$refs.easRoom.setSearchData(data.buildUnitIds);
        }
      })
    }
  }
}
</script>
<style lang="less" >
.ivu-col {
  width: 100%;
}
.title {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.title button {
  margin-left: 2px;
}

.title .title-span {
  position: absolute;
  left: 10px;
  top: 10px;
}
.ivu-btn {
  padding: 0 10px !important;
}
.search {
  .operation {
    margin-bottom: 2vh;
  }

  .select-count {
    font-size: 13px;
    font-weight: 600;
    color: #40a9ff;
  }

  .select-clear {
    margin-left: 10px;
  }

  .page {
    margin-top: 2vh;
  }

  .drop-down {
    font-size: 13px;
    margin-left: 5px;
  }
}
.drawer-footer {
  z-index: 10;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}

.input1Search {
  width: 100%;
}

.ivu-table .demo-table-info-row td {
  background-color: #2db7f5;
  color: #fff;
}

.ivu-table .demo-table-error-row td {
  background-color: #ff6600;
  color: #fff;
}

.ivu-table td.demo-table-info-column {
  background-color: #2db7f5;
  color: #fff;
}

.ivu-table .demo-table-info-cell-name {
  background-color: #2db7f5;
  color: #fff;
}

.ivu-table .demo-table-info-cell-age {
  background-color: #ff6600;
  color: #fff;
}

.ivu-table .demo-table-info-cell-address {
  background-color: #187;
  color: #fff;
}

.demo-upload-list {
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}
.text-list {
  margin-bottom: 3px;
  border-bottom-width: 2px;
  border-bottom-style: dashed;
  border-bottom-color: #999;
}
.text-list:last-child {
  border-bottom-width: 0px;
  border-bottom-style: none;
}

.demo-upload-list img {
  width: 100%;
  height: 100%;
}

.demo-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}

.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}

.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}

.ql-editor {
  min-height: 400px;
}

.face {
  display: none;
}

.ivu-select-dropdown {
  text-align: center;
  z-index: 1024;
}
.line {
  height: 2px;
  margin-bottom: 10px;
  background-color: #b39cd0;
}
.modal-content {
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: rgba(242, 242, 242, 1);

  .modal-info {
    width: 40%;
    border: 1px dashed #aaa;

    .modal-info_title {
      justify-content: space-evenly;
      align-items: center;

      .modal-info_title_cell {
        flex-direction: column;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 8px;

        span:nth-of-type(1) {
          margin-bottom: 10px;
        }
      }
    }

    .modal-info_detail {
      .modal-info_detail_cell {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        margin: 0 10px;
        font-size: 16px;
        background-color: rgba(255, 255, 255, 1);
      }
    }

    .text-index {
      font-size: 16px;
      font-weight: bold;
    }

    .modify-text {
      font-size: 14px;

      .err-text {
        span:nth-of-type(1) {
          color: #d9001b;
          font-weight: bold;
        }
      }

      .amend-text {
        span:nth-of-type(1) {
          color: rgb(21, 255, 0);
          font-weight: bold;
        }
      }

      .explain {
        span:nth-of-type(1) {
          color: #02a7f0;
          font-weight: bold;
        }
      }
    }
  }

  .modal-text {
    width: 60%;
    flex: 1;
  }
}

.audit-wrapper {
  width: 350px;
  border-bottom: 2px solid #888;
}
.info-wrapper {
  width: 350px;
  border-bottom: 2px solid #888;
}
.audit-title {
  font-size: 26px;
  font-weight: bold;
}

.basic-title {
  font-size: 26px;
  font-weight: bold;
  margin: 20px 0;
}

.audit-img {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100px;
  }
}

.audit-status {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: black;
  font-weight: bold;
}

.timeline-wrapper {
  box-sizing: border-box;
  padding: 16px 30px 16px 20px;
}

.audit-info {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.64);
}

::v-deep .ivu-select-dropdown {
  z-index: 9999;
}

.el-timeline-item__timestamp {
  color: #3399ff;
}

.title1 {
  font-size: 14px;
  color: #3399ff;
  padding-bottom: 20px;
}

.title2 {
  font-size: 14px;
  color: #333;
  padding-bottom: 20px;
}

.title3 {
  font-size: 14px;
  color: #333;
  opacity: 0.4;
  padding-bottom: 20px;
}

.dot1 {
  width: 24px;
  height: 24px;
  font-size: 14px;
  border: 1px solid #3399ff;
  color: #3399ff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
}

.dot2 {
  width: 24px;
  height: 24px;
  font-size: 14px;
  border: 1px solid #3399ff;
  background-color: #3399ff;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
}

.dot3 {
  width: 24px;
  height: 24px;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
}

.audit-card {
  .clearfix {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
  }

  .opinion {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
  }
}
.ivu-card-body {
  padding: 12px !important;
  margin-bottom: 40px !important;
}
</style>
